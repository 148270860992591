import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import CustomCard from '../../components/Card'
import { getRequest } from '../../utils/requestUtils'
import moment from 'moment'
import ActionButtonGroup from '../../components/ActionButtonGroup'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { FilterMatchMode } from 'primereact/api'
import 'primeicons/primeicons.css'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'react-bootstrap'
import { Badge } from 'primereact/badge'
import useFetch from '../../hooks/useFetch'
import { validate } from '../../api/invoice'

const InvoiceTable = ({ type }) => {
  const { loading, data, errors } = useFetch({ route: `/invoices/${type}` })
  const [invoices, setInvoices] = useState([])
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  })
  const [globalFilterValue, setGlobalFilterValue] = useState('')

  useEffect(() => {
    setInvoices(data)
  }, [data, type])

  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    let _filters = { ...filters }

    _filters['global'].value = value

    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  const handleMarkAsValidated = async (invoice) => {
    const { data } = await validate(invoice)

    const newInvoices = invoices.map((minvoice) => {
      if (invoice.id === minvoice.id) {
        return { ...invoice, ...data }
      }

      return minvoice
    })

    setInvoices(newInvoices)
  }

  const renderHeader = () => {
    return (
      <div className="text-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Recherche..."
          />
        </span>
      </div>
    )
  }

  return (
    <CustomCard title={type === 1 ? 'Factures' : 'Devis'}>
      {type === 1 ? (
        <Link to="/invoices/new" className="btn btn-primary">
          Ajouter une facture
        </Link>
      ) : (
        <Link to="/quotes/new" className="btn btn-primary">
          Ajouter un devis
        </Link>
      )}
      <DataTable
        loading={loading}
        value={invoices}
        paginator
        rows={20}
        rowsPerPageOptions={[10, 20, 30]}
        emptyMessage="Aucun résultat."
        globalFilterFields={['customer_name']}
        removableSort
        filters={filters}
        header={renderHeader}
      >
        <Column
          field="id"
          header="#"
          sortable
          align="right"
          alignHeader={'right'}
        />
        <Column
          field="formatted_number"
          header="Numéro"
          sortable
          align="right"
        />
        <Column field="customer_name" header="Client" sortable />
        <Column
          field="total_incl_tax"
          header="Montant"
          body={(invoice) => invoice.total_incl_tax.toFixed(2) + ' €'}
          sortable
          alignHeader="right"
          align="right"
        />
        <Column
          field="validated_at"
          header="Statut"
          body={(invoice) =>
            invoice.validatedAt ? (
              <Badge
                value={type === 1 ? 'Payée' : 'Accepté'}
                severity="success"
              />
            ) : (
              <Badge value="En cours" severity="warning" />
            )
          }
          sortable
          align="left"
        />
        <Column
          field="createdAt"
          header="Date"
          body={(invoice) => moment(invoice.createdAt).format('DD/MM/YYYY')}
          sortable
          align="right"
        />
        <Column
          header="Actions"
          body={(invoice) => (
            <ActionButtonGroup
              type={type === 1 ? 'invoices' : 'quotes'}
              id={invoice.id}
            >
              <Dropdown.Item
                className="text-success"
                onClick={() => handleMarkAsValidated(invoice)}
              >
                {invoice.type === 1
                  ? 'Marquer comme payée'
                  : 'Marquer comme accepté'}
              </Dropdown.Item>
            </ActionButtonGroup>
          )}
        ></Column>
      </DataTable>
    </CustomCard>
  )
}

export default InvoiceTable
