import React, { useEffect, useState } from 'react'
import FormInput from './FormInput'
import { Button } from 'react-bootstrap'
import { round } from 'lodash'
import { calculPriceInclTax } from '../../utils/invoiceUtils'
import { AutoComplete } from 'primereact/autocomplete'
import 'primereact/resources/primereact.min.css'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import { InputText } from 'primereact/inputtext'

export default function InvoiceLineForm({
  product,
  id,
  invoice,
  setInvoice,
  products,
  subtotal,
}) {
  const [filteredProducts, setFilteredProducts] = useState([])
  const TYPE_PRODUCT = 1
  const TYPE_TITLE = 2
  const TYPE_SUBTOTAL = 3

  const search = (event) => {
    let _filteredProducts

    if (!event.query.trim().length) {
      _filteredProducts = [...products]
    } else {
      _filteredProducts = products.filter((product) => {
        return product.text.toLowerCase().startsWith(event.query.toLowerCase())
      })
    }

    setFilteredProducts(_filteredProducts)
  }

  const handleChange = (e) => {
    const tempProducts = invoice.products.slice()

    const name = e.target.name
    tempProducts[id][name] = e.target.value

    if (product.type === TYPE_PRODUCT) {
      const priceWithTax = calculPriceInclTax(tempProducts[id])
      tempProducts[id]['price_incl_tax'] = round(priceWithTax, 2).toFixed(2)
    }

    setInvoice({
      ...invoice,
      products: tempProducts,
    })
  }

  const handleDelete = () => {
    const tempProducts = invoice.products.slice()

    tempProducts.splice(id, 1)

    setInvoice({
      ...invoice,
      products: tempProducts,
    })
  }

  const handleMove = (direction) => {
    const tempProducts = invoice.products

    if (direction === 'up') {
      if (id > 0) {
        const tempPreviousProduct = tempProducts[id - 1]

        tempProducts[id - 1] = product
        tempProducts[id] = tempPreviousProduct

        setInvoice({ ...invoice, products: tempProducts })
      }
    } else {
      if (id < invoice.products.length - 1) {
        const tempNextProduct = tempProducts[id + 1]

        tempProducts[id + 1] = product
        tempProducts[id] = tempNextProduct

        setInvoice({ ...invoice, products: tempProducts })
      }
    }
  }

  const orderButtons = () => {
    return (
      <td style={{ maxWidth: '35px', textAlign: 'center' }}>
        <span onClick={() => handleMove('up')} title="Monter" role="button">
          <i className="pi pi-arrow-up"></i>
        </span>
        <span
          onClick={() => handleMove('down')}
          title="Descendre"
          role="button"
        >
          <i className="pi pi-arrow-down"></i>
        </span>
      </td>
    )
  }

  if (product.type === TYPE_TITLE) {
    return (
      <tr>
        {orderButtons()}
        <td colSpan={5}>
          <InputText
            name="text"
            value={product.text ?? 'Titre'}
            onChange={handleChange}
            className="w-100"
          />
        </td>
        <td>
          <Button variant="link" onClick={handleDelete}>
            Supprimer
          </Button>
        </td>
      </tr>
    )
  }

  if (product.type === TYPE_SUBTOTAL) {
    return (
      <tr>
        {orderButtons()}
        <td colSpan={4}>
          <InputText value="Sous-total" className="w-100 p-readonly" readOnly />
        </td>
        <td>
          <FormInput
            value={Number(product.price_incl_tax)?.toFixed(2)}
            className="w-100"
            symbol="&euro;"
            disabled
          />
        </td>
        <td>
          <Button variant="link" onClick={handleDelete}>
            Supprimer
          </Button>
        </td>
      </tr>
    )
  }

  return (
    <tr>
      {orderButtons()}
      <td>
        {products && (
          <AutoComplete
            name="text"
            value={product.text}
            suggestions={filteredProducts}
            field="text"
            maxLength="255"
            completeMethod={search}
            onChange={(e) => {
              const tempProducts = invoice.products.slice()

              if (typeof e.value === 'object') {
                tempProducts[id] = e.value
                tempProducts[id].quantity = 1
                tempProducts[id].price_incl_tax = calculPriceInclTax({
                  price: e.value.price,
                  tax: e.value.tax,
                  quantity: 1,
                })
              } else {
                tempProducts[id].text = e.target.value
              }

              tempProducts[id].type = 1

              setInvoice({
                ...invoice,
                products: tempProducts,
              })
            }}
            className="w-100"
            inputClassName="w-100"
          />
        )}
      </td>
      <td>
        <FormInput
          type="number"
          name="price"
          value={product.price}
          stateChanger={handleChange}
          required
          symbol="&euro;"
        />
      </td>
      <td>
        <FormInput
          type="number"
          name="quantity"
          value={product.quantity}
          stateChanger={handleChange}
          required
        />
      </td>
      <td>
        <FormInput
          type="number"
          name="tax"
          value={product.tax}
          stateChanger={handleChange}
          symbol="%"
        />
      </td>
      <td>
        <FormInput
          name="price_incl_tax"
          value={product.price_incl_tax}
          stateChanger={handleChange}
          symbol="&euro;"
        />
      </td>
      <td>
        <Button variant="link" onClick={handleDelete}>
          Supprimer
        </Button>
      </td>
    </tr>
  )
}
