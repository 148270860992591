import React, { useEffect, useState } from 'react'
import {
  getRequest,
  putRequest,
  domain,
  uploadFile,
} from '../../utils/requestUtils'
import CustomCard from '../../components/Card'
import { Col, Form, FormControl, Row } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { Button } from 'primereact/button'
import CompanyForm from '../../components/Form/CompanyForm'
import { Image } from 'primereact/image'
import { toast } from 'react-toastify'

function UpdateCompany() {
  const [company, setCompany] = useState({
    name: '',
    address: '',
    address2: '',
    city: '',
    zipcode: '',
    country: '',
    siren: '',
  })
  const [isButtonLoading, setButtonLoading] = useState(false)

  const { id } = useParams()

  useEffect(() => {
    getRequest('/company/' + id).then((response) => {
      setCompany(response.data)
    })
  }, [id])

  const handleSubmit = async (e) => {
    e.preventDefault()

    setButtonLoading(true)

    putRequest('/company/' + id, JSON.stringify(company)).then((data) => {
      toast.success('Sauvegarde réussie !')
      setButtonLoading(false)
    })
  }

  const handleUpload = (e) => {
    const files = e.target.files

    const formData = new FormData()
    for (const file of files) {
      if (file.type.match(/(image\/).*/)) {
        formData.append('data', file)
      }
    }

    uploadFile(`/company/${id}/upload/logo`, formData).then((response) => {
      setCompany(response.data)
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col md="6">
          <CustomCard title="Modifier la société">
            <CompanyForm company={company} setCompany={setCompany} />
            <Button
              type="submit"
              label="Enregistrer"
              disabled={isButtonLoading}
              loading={isButtonLoading}
            ></Button>
          </CustomCard>
        </Col>
        <Col md="6">
          <CustomCard title="Logo">
            {company.logo && (
              <Image
                src={`${domain}/files/${company.logo}`}
                alt={`Logo ${company.name}`}
                width="300"
                preview
                className="mb-3"
              />
            )}
            <FormControl type="file" onChange={(e) => handleUpload(e)} />
          </CustomCard>
        </Col>
      </Row>
    </Form>
  )
}

export default UpdateCompany
