import React, { useState } from 'react'
import CustomCard from '../../components/Card'
import { postRequest } from '../../utils/requestUtils'
import InvoiceForm from '../../components/Form/InvoiceForm'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { isValid } from '../../utils/invoiceUtils'

function NewInvoice({ type = 1 }) {
  const [invoice, setInvoice] = useState({
    company_id: null,
    customer_id: null,
    products: [
      {
        text: '',
        quantity: 0,
        price: 0.0,
        tax: 0,
        price_incl_tax: 0.0,
        type: 1,
      },
    ],
    total_excl_tax: 0,
    discount: 0,
    total_incl_tax: 0,
    total_vat: 0,
    text: '',
    type,
  })
  const [isButtonLoading, setButtonLoading] = useState(false)
  const navigate = useNavigate()

  const handleSubmit = (e) => {
    e.preventDefault()

    setButtonLoading(true)

    if (isValid(invoice)) {
      postRequest('/invoice/new', invoice)
        .then((response) => {
          if (response.status === 200) {
            const invoiceId = response.data.id
            const invoiceType = response.data.type === 1 ? 'invoices' : 'quotes'

            toast.success('Sauvegarde réussie !')
            navigate(`/${invoiceType}/${invoiceId}`)
          }
        })
        .finally(() => {
          setButtonLoading(false)
        })
    } else {
      setButtonLoading(false)
    }
  }

  return (
    <CustomCard title={type === 1 ? 'Ajouter une facture' : 'Ajouter un devis'}>
      <InvoiceForm
        invoice={invoice}
        setInvoice={setInvoice}
        handleSubmit={handleSubmit}
        isButtonLoading={isButtonLoading}
      />
    </CustomCard>
  )
}

export default NewInvoice
