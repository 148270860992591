import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Dashboard from './views/Dashboard'
import NotFound from './views/NotFound'
import UserProfile from './views/User/UserProfile'

import CompanyTable from './views/Company/CompanyTable'
import NewCompany from './views/Company/NewCompany'
import UpdateCompany from './views/Company/UpdateCompany'

import CustomerTable from './views/Customer/CustomerTable'
import NewCustomer from './views/Customer/NewCustomer'
import UpdateCustomer from './views/Customer/UpdateCustomer'

import InvoiceTable from './views/Invoice/InvoiceTable'
import NewInvoice from './views/Invoice/NewInvoice'
import UpdateInvoice from './views/Invoice/UpdateInvoice'

import Header from './components/_common/Header'
import Nav from './components/_common/Nav'
import Footer from './components/_common/Footer'
import Products from './views/Product/Products'
import NewProduct from './views/Product/NewProduct'
import UpdateProduct from './views/Product/UpdateProduct'
import { useUser } from './hooks/useUser'
import SignIn from './views/SignIn'
import VerifyEmail from './views/VerifyEmail'
import Login from './views/Login'

function App() {
  const { user } = useUser()
  const TYPE_INVOICE = 1
  const TYPE_QUOTE = 2

  if (!user) {
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={true}
        />
        <Routes>
          <Route path="/register" element={<SignIn />} />
          <Route path="/verify-email/:token" element={<VerifyEmail />} />
          <Route path="*" element={<Login />} />
        </Routes>
      </>
    )
  }

  return (
    <>
      <Header />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
      />
      <div className="row my-4">
        <div className="col-md-2">
          <Nav />
        </div>
        <div className="col-md-10">
          <main>
            <Routes>
              <Route name="dashboard" path="/" element={<Dashboard />} />

              <Route
                name="companies"
                path="/companies"
                element={<CompanyTable />}
              />
              <Route
                name="companies_new"
                path="/companies/new"
                element={<NewCompany />}
              />
              <Route
                name="companies_update"
                path="/companies/:id"
                element={<UpdateCompany />}
              />

              <Route
                name="customers"
                path="/customers"
                element={<CustomerTable />}
              />
              <Route
                name="customers_new"
                path="/customers/new"
                element={<NewCustomer />}
              />
              <Route
                name="customers_update"
                path="/customers/:id"
                element={<UpdateCustomer />}
              />
              <Route name="products" path="/products" element={<Products />} />
              <Route
                name="products_new"
                path="/products/new"
                element={<NewProduct />}
              />
              <Route
                name="product_update"
                path="/products/:id"
                element={<UpdateProduct />}
              />

              <Route
                name="invoices"
                path="/invoices"
                element={<InvoiceTable type={TYPE_INVOICE} />}
              />
              <Route
                name="invoices_new"
                path="/invoices/new"
                element={<NewInvoice type={TYPE_INVOICE} />}
              />
              <Route
                name="quotes_update"
                path="/invoices/:id"
                element={<UpdateInvoice type={TYPE_INVOICE} />}
              />

              <Route
                name="quotes"
                path="/quotes"
                element={<InvoiceTable type={TYPE_QUOTE} />}
              />
              <Route
                name="quotes_new"
                path="/quotes/new"
                element={<NewInvoice type={TYPE_QUOTE} />}
              />
              <Route
                name="quotes_update"
                path="/quotes/:id"
                element={<UpdateInvoice type={TYPE_QUOTE} />}
              />

              <Route path="/profile" element={<UserProfile />} />

              <Route path="/*" element={<NotFound />} />
            </Routes>
          </main>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default App
