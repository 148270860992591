import React from 'react'
import FormInput from './FormInput'

const CompanyForm = ({ company, setCompany }) => {
  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value

    setCompany({ ...company, [name]: value })
  }

  return (
    <>
      <FormInput
        label="Nom"
        placeholder="Nom de l'entreprise"
        type="text"
        name="name"
        value={company.name}
        stateChanger={handleChange}
        required
      />
      <FormInput
        label="Adresse ligne 1"
        type="text"
        name="address"
        value={company.address}
        stateChanger={handleChange}
        required
      />
      <FormInput
        label="Adresse ligne 2"
        type="text"
        name="address2"
        value={company.address2}
        stateChanger={handleChange}
      />
      <FormInput
        label="Code postal"
        type="text"
        name="zipcode"
        value={company.zipcode}
        data={company}
        stateChanger={handleChange}
        required
      />
      <FormInput
        label="Ville"
        type="text"
        name="city"
        value={company.city}
        stateChanger={handleChange}
        required
      />
      <FormInput
        label="Pays"
        name="country"
        value="FR"
        //value={company.country}
        //stateChanger={handleChange}
      />
      <FormInput
        label="SIREN"
        name="siren"
        value={company.siren}
        stateChanger={handleChange}
        required
        minLength={9}
      />
    </>
  )
}

export default CompanyForm
