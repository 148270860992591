import React, { useEffect, useState } from 'react'
import CustomCard from '../components/Card'
import { Chart } from 'primereact/chart'
import { getRequest } from '../utils/requestUtils'

const Dashboard = () => {
  const [chartData, setChartData] = useState({})

  const extractStats = (resultData) => resultData.data.map((month) => month.sum)

  const getStats = async () => {
    const lastYearData = await getRequest('/stats/2023')
    const currentYearData = await getRequest('/stats')

    const chart = {
      labels: [],
      datasets: [
        {
          label: '2023',
          data: lastYearData.data,
        },
        {
          label: '2024',
          data: currentYearData.data,
        },
      ],
    }

    setChartData(chart)
  }

  useEffect(() => {
    getStats()
  }, [])

  return (
    <>
      <CustomCard title="Graphiques de l'année en cours">
        <Chart type="bar" data={chartData} />
      </CustomCard>
    </>
  )
}

export default Dashboard
