import React, { useState } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import CustomCard from '../../components/Card'
import FormInput from '../../components/Form/FormInput'
import { postRequest } from '../../utils/requestUtils'
import { Button } from 'primereact/button'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

function NewCustomer() {
  const [customer, setCustomer] = useState({
    name: '',
    address: '',
    address2: '',
    city: '',
    zipcode: '',
    country: '',
  })
  const [isButtonLoading, setButtonLoading] = useState(false)
  const navigate = useNavigate()

  const handleChange = (e, name) => {
    const value = e.target.value

    setCustomer({ ...customer, [name]: value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setButtonLoading(true)

    postRequest('/customer/new', JSON.stringify(customer)).then((response) => {
      toast.success('Client ajouté !')
      navigate(`/customers/${response.data.id}`)
      setButtonLoading(false)
    })
  }

  return (
    <CustomCard>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md="6">
            <h2>Nouveau client</h2>
            <FormInput
              label="Nom"
              value={customer.name}
              stateChanger={(e) => handleChange(e, 'name')}
            />
            <FormInput
              label="Adresse"
              value={customer.address}
              stateChanger={(e) => handleChange(e, 'address')}
            />
            <FormInput
              label="Adresse 2"
              value={customer.address2}
              stateChanger={(e) => handleChange(e, 'address2')}
            />
            <FormInput
              label="Ville"
              value={customer.city}
              stateChanger={(e) => handleChange(e, 'city')}
            />
            <FormInput
              label="Code postal"
              value={customer.zipcode}
              stateChanger={(e) => handleChange(e, 'zipcode')}
            />
            <FormInput
              label="Pays"
              value="FR"
              //value={customer.country}
              //stateChanger={(e) => handleChange(e, 'country')}
            />
            <Button
              type="submit"
              label="Enregistrer"
              disabled={isButtonLoading}
              loading={isButtonLoading}
            ></Button>
          </Col>
        </Row>
      </Form>
    </CustomCard>
  )
}

export default NewCustomer
