import { Card } from 'react-bootstrap'

function CustomCard({ children, title, className, bodyClassName = '' }) {
  return (
    <Card className={`border-0 shadow-sm ${className}`}>
      <Card.Body className={bodyClassName}>
        {title && <Card.Title>{title}</Card.Title>}
        {children}
      </Card.Body>
    </Card>
  )
}

export default CustomCard
