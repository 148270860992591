import React, { useState } from 'react'
import FormInput from '../components/Form/FormInput'
import { Form } from 'react-bootstrap'
import { Button } from 'primereact/button'
import CustomCard from '../components/Card'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { getRequest, postRequest } from '../utils/requestUtils'
import { toast } from 'react-toastify'
import { useUser } from '../hooks/useUser'

const Login = () => {
  const { user, setUser } = useUser()

  const [login, setLogin] = useState({})
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault()

    setIsButtonLoading(true)
    postRequest('/login', login).then((response) => {
      if (200 !== response.status) {
        toast.error('Connexion impossible')
        return
      }

      if (response.data.token) {
        localStorage.setItem('token', response.data.token)
      }

      if (localStorage.getItem('token')) {
        getRequest('/me')
          .then(({ data }) => {
            localStorage.setItem('user', JSON.stringify(data))

            setUser(data)

            navigate('/')

            toast.success('Connexion réussie...')
          })
          .finally(() => {
            setIsButtonLoading(false)
          })
      }
    })
  }

  const handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value

    setLogin({ ...login, [name]: value })
  }

  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
          <CustomCard className="mx-auto mt-5">
            <Form onSubmit={(e) => handleSubmit(e)}>
              <div className="text-center">
                <img
                  src="/images/logo-raccoonts.svg"
                  alt="Raccoonts"
                  width="100"
                />
              </div>
              <h1>Connexion</h1>
              <FormInput
                label="Adresse email"
                type="email"
                name="username"
                value={login.username}
                stateChanger={handleChange}
                required
              />
              <FormInput
                label="Mot de passe"
                type="password"
                name="password"
                value={login.password}
                stateChanger={handleChange}
                required
              />
              <Button
                type="submit"
                label={'Connexion'}
                loading={isButtonLoading}
              />
            </Form>
          </CustomCard>
          <div className="text-center p-4">
            <Link to="/register">S'inscrire</Link> -{' '}
            <a href="/#">Mot de passe oublié</a>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
