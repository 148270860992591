import React, { useState } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import CustomCard from '../../components/Card'
import { postRequest } from '../../utils/requestUtils'
import { Button } from 'primereact/button'
import CompanyForm from '../../components/Form/CompanyForm'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

function NewCompany() {
  const [company, setCompany] = useState({
    name: '',
    address: '',
    address2: '',
    city: '',
    zipcode: '',
    country: '',
    siren: '',
  })
  const [isButtonLoading, setButtonLoading] = useState(false)
  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault()

    setButtonLoading(true)

    postRequest('/company/new', JSON.stringify(company)).then((response) => {
      toast.success('Société ajoutée !')
      setButtonLoading(false)
      navigate('/companies/' + response.data.id)
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col md="6">
          <CustomCard>
            <h2>Nouvelle société</h2>
            <CompanyForm company={company} setCompany={setCompany} />
            <Button
              type="submit"
              label="Enregistrer"
              disabled={isButtonLoading}
              loading={isButtonLoading}
            ></Button>
          </CustomCard>
        </Col>
      </Row>
    </Form>
  )
}

export default NewCompany
