import ProductForm from '../../components/Form/ProductForm'
import { useState } from 'react'
import CustomCard from '../../components/Card'
import { Col, Row } from 'react-bootstrap'
import { postRequest } from '../../utils/requestUtils'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

function NewProduct() {
  const [product, setProduct] = useState({
    name: '',
    price: 0,
    quantity: 0,
    tax: 0,
  })
  const [isButtonLoading, setButtonLoading] = useState(false)

  const navigate = useNavigate()

  const handleChange = (e, name) => {
    setProduct({ ...product, ...{ [name]: e.target.value } })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    setButtonLoading(true)

    postRequest('/product/new', product).then((response) => {
      toast.success('Produit ajouté')
      navigate('/products/' + response.data.id)
      setButtonLoading(false)
    })
  }

  return (
    <>
      <CustomCard title="Ajouter un nouveau produit">
        <Row>
          <Col md={4}>
            <ProductForm
              product={product}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              isButtonLoading={isButtonLoading}
            />
          </Col>
        </Row>
      </CustomCard>
    </>
  )
}

export default NewProduct
