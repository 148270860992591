import { NavLink, useLocation } from 'react-router-dom'
import CustomCard from '../Card'
import React from 'react'

function Nav() {
  const navLinks = [
    { route: '/', label: 'Dashboard', end: true },
    { route: '/companies', label: 'Mes sociétés' },
    { route: '/customers', label: 'Mes clients' },
    { route: '/products', label: 'Mes produits' },
    { route: '/quotes', label: 'Devis' },
    { route: '/invoices', label: 'Factures' },
    //{ route: '/expenses', label: 'Dépenses' },
  ]

  const location = useLocation()

  return (
    <CustomCard className="sticky-top">
      <nav>
        <ul className="list-unstyled">
          {navLinks.map(({ route, label, end }, index) => (
            <li key={index} className="">
              <NavLink
                to={route}
                key={index}
                end={end}
                className={({ isActive }) =>
                  `btn p-3 my-2 w-100 text-start ${
                    isActive ? `btn-primary` : `btn-light`
                  }`
                }
              >
                {label}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </CustomCard>
  )
}

export default Nav
