import React from 'react'

function Footer() {
  const today = new Date()

  return (
    <footer className="py-3 bg-white shadow-sm rounded-3">
      <p className="text-center mb-0">
        Copyright &copy; {today.getFullYear()}{' '}
        <a href="https://plumia.net">Plumia</a>
      </p>
    </footer>
  )
}

export default Footer
