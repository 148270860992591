import React, { createContext, useContext, useEffect, useState } from 'react'

const UserContext = createContext({})

export function useUser() {
  const { user, setUser } = useContext(UserContext)

  return { user, setUser }
}

export function UserContextProvider({ children }) {
  const [user, setUser] = useState(false)

  useEffect(() => {
    const storedUser = localStorage.getItem('user')

    if (storedUser) {
      setUser(JSON.parse(storedUser))
    }
  }, [])

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  )
}
