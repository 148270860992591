import FormInput from './FormInput'
import { Form } from 'react-bootstrap'
import React from 'react'
import { Button } from 'primereact/button'

function ProductForm({ product, handleChange, handleSubmit, isButtonLoading }) {
  return (
    <>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <FormInput
          label="Nom du produit"
          type="text"
          name="text"
          value={product.name}
          stateChanger={(e) => handleChange(e, 'name')}
        />
        <FormInput
          label="Prix HT"
          type="text"
          name="price"
          value={product.price}
          stateChanger={(e) => handleChange(e, 'price')}
          symbol="&euro;"
        />
        <FormInput
          label="TVA"
          type="number"
          name="tax"
          value={product.tax}
          stateChanger={(e) => handleChange(e, 'tax')}
          symbol="%"
        />
        <FormInput
          label="Stock"
          type="number"
          name="quantity"
          value={product.quantity}
          stateChanger={(e) => handleChange(e, 'quantity')}
        />
        <Button
          type="submit"
          label="Enregistrer"
          disabled={isButtonLoading}
          loading={isButtonLoading}
        ></Button>
      </Form>
    </>
  )
}

export default ProductForm
