import React, { useState } from 'react'
import FormInput from '../components/Form/FormInput'
import Card from '../components/Card'
import { Alert, Form } from 'react-bootstrap'
import { Button } from 'primereact/button'
import CustomCard from '../components/Card'
import { Link } from 'react-router-dom'
import { postRequest } from '../utils/requestUtils'
import { toast } from 'react-toastify'

const SignIn = () => {
  const [isSubmit, setIsSubmit] = useState(false)
  const [isButtonLoading, setIsButtonLoading] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()

    setIsButtonLoading(true)
    const formData = JSON.stringify(Object.fromEntries(new FormData(e.target)))

    postRequest('/register', formData)
      .then((response) => {
        if (200 !== response.status) {
          toast.error('Inscription impossible')
          return
        }

        setIsSubmit(true)
      })
      .catch((error) => {
        toast.error('Inscription impossible')
      })
      .finally(() => {
        setIsButtonLoading(false)
      })
  }

  if (process.env.REACT_APP_SIGNIN !== 'open') {
    return (
      <Alert key="danger" variant="danger" className="w-50 mt-5 mx-auto">
        Merci pour votre intérêt pour Raccoonts.
        <br />
        Malheureusement, les inscriptions ne sont pas encore ouvertes mais
        revenez plus tard.
        <br />
      </Alert>
    )
  }

  if (isSubmit) {
    return (
      <Card className="w-50 mt-5 mx-auto border border-4 border-success">
        <h3>Votre inscription est bien prise en compte.</h3>
        <p>
          Un email avec un lien de confirmation a été envoyé sur l'adresse que
          vous avez renseigné.
        </p>
      </Card>
    )
  }

  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
          <CustomCard className="mt-5">
            <Form onSubmit={(e) => handleSubmit(e)}>
              <div className="text-center">
                <img
                  src="/images/logo-raccoonts.svg"
                  alt="Raccoonts"
                  width="100"
                />
              </div>
              <h1>Inscription</h1>
              <FormInput
                label="Adresse email"
                type="email"
                name="email"
                required
              />
              <FormInput
                label="Mot de passe"
                type="password"
                name="password"
                minLength={6}
                required
              />
              <FormInput
                type="password"
                label="Confirmation du mot de passe"
                name="confirmPassword"
                minLength={6}
                required
              />
              <FormInput
                label="Prénom"
                type="text"
                name="firstname"
                minLength="3"
                required
              />
              <FormInput
                label="Nom"
                type="text"
                name="lastname"
                minLength="3"
                required
              />
              <FormInput
                label="Date de naissance"
                type="date"
                name="birthdate"
                required
              />
              <Button
                type="submit"
                label={'Inscription'}
                loading={isButtonLoading}
              />
            </Form>
          </CustomCard>
          <div className="text-center p-4">
            <Link to="/login">Connexion</Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default SignIn
