import { ButtonGroup, DropdownButton } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function ActionButtonGroup({ children, type, id, onDelete }) {
  return (
    <ButtonGroup>
      <Link to={`/${type}/${id}`} className="btn btn-outline-primary">
        Modifier
      </Link>
      <DropdownButton as={ButtonGroup} title="" variant="outline-primary">
        {/*
          <Dropdown.Item className="text-danger" onClick={onDelete}>
          Supprimer
        </Dropdown.Item>
           */}
        {children}
      </DropdownButton>
    </ButtonGroup>
  )
}

export default ActionButtonGroup
