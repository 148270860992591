import React, { useEffect, useState } from 'react'
import CustomCard from '../../components/Card'
import { Link } from 'react-router-dom'
import moment from 'moment/moment'
import ActionButtonGroup from '../../components/ActionButtonGroup'
import { getRequest } from '../../utils/requestUtils'

function Products() {
  const [products, setProducts] = useState()

  useEffect(() => {
    getRequest('/products').then((response) => {
      setProducts(response.data)
    })
  }, [])

  const onDelete = () => {}

  return (
    <CustomCard title="Mes produits">
      <Link to="/products/new" className="btn btn-primary">
        Ajouter un nouveau produit
      </Link>
      <table className="table table-striped my-4">
        <thead>
          <tr>
            <th>#</th>
            <th>Description</th>
            <th>Prix</th>
            <th>Taxe</th>
            <th>Crée le</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {products?.map((product) => (
            <tr key={product.id}>
              <td>{product.id}</td>
              <td>{product.name}</td>
              <td>{product.price}&nbsp;€</td>
              <td>{product.tax}&nbsp;%</td>
              <td>{moment(product.created_at).format('DD/MM/YYYY')}</td>
              <td>
                <ActionButtonGroup
                  id={product.id}
                  type="products"
                  onDelete={() => onDelete(product.id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </CustomCard>
  )
}

export default Products
