import React, { useEffect, useState } from 'react'
import { getRequest, putRequest } from '../../utils/requestUtils'
import CustomCard from '../../components/Card'
import { Col, Form, Row } from 'react-bootstrap'
import FormInput from '../../components/Form/FormInput'
import { useParams } from 'react-router-dom'
import { Button } from 'primereact/button'
import { toast } from 'react-toastify'

function UpdateCustomer() {
  const [customer, setCustomer] = useState({
    name: '',
    address: '',
    city: '',
    zipcode: '',
    country: '',
  })
  const [isButtonLoading, setButtonLoading] = useState(false)
  const { id } = useParams()

  useEffect(() => {
    getRequest('/customer/' + id).then((response) => {
      setCustomer(response.data)
    })
  }, [id])

  const handleChange = (e, name) => {
    const value = e.target.value

    setCustomer({ ...customer, [name]: value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setButtonLoading(true)

    putRequest('/customer/' + id, JSON.stringify(customer)).then((data) => {
      toast.success('Sauvegarde réussie !')
      setButtonLoading(false)
    })
  }

  return (
    <CustomCard title="Modifier le client">
      <Row>
        <Col md="6">
          <Form onSubmit={handleSubmit}>
            <FormInput
              label="Nom"
              value={customer.name}
              stateChanger={(e) => handleChange(e, 'name')}
            />
            <FormInput
              label="Adresse"
              value={customer.address}
              stateChanger={(e) => handleChange(e, 'address')}
            />
            <FormInput
              label="Adresse 2"
              value={customer.address2}
              stateChanger={(e) => handleChange(e, 'address2')}
            />
            <FormInput
              label="Ville"
              value={customer.city}
              stateChanger={(e) => handleChange(e, 'city')}
            />
            <FormInput
              label="Code postal"
              value={customer.zipcode}
              stateChanger={(e) => handleChange(e, 'zipcode')}
            />
            <FormInput
              label="Pays"
              value="FR"
              //value={customer.country}
              //stateChanger={(e) => handleChange(e, 'country')}
            />
            <Button
              type="submit"
              label="Enregistrer"
              disabled={isButtonLoading}
              loading={isButtonLoading}
            ></Button>
          </Form>
        </Col>
      </Row>
    </CustomCard>
  )
}

export default UpdateCustomer
