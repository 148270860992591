import ProductForm from '../../components/Form/ProductForm'
import { useEffect, useState } from 'react'
import CustomCard from '../../components/Card'
import { Col, Row } from 'react-bootstrap'
import { getRequest, putRequest } from '../../utils/requestUtils'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

function UpdateProduct() {
  const { id } = useParams()

  const [product, setProduct] = useState({
    name: '',
    price: 0,
    quantity: 0,
    tax: 0,
  })
  const [isButtonLoading, setButtonLoading] = useState(false)

  useEffect(() => {
    getRequest('/product/' + id).then(({ data }) => {
      setProduct(data)
    })
  }, [id])

  const handleChange = (e, name) => {
    setProduct({ ...product, ...{ [name]: e.target.value } })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    setButtonLoading(true)

    putRequest('/product/' + id, product).then((data) => {
      toast.success('Produit modifié !')
      setButtonLoading(false)
    })
  }

  return (
    <>
      <CustomCard title="Modifier le produit">
        <Row>
          <Col md={4}>
            <ProductForm
              product={product}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              isButtonLoading={isButtonLoading}
            />
          </Col>
        </Row>
      </CustomCard>
    </>
  )
}

export default UpdateProduct
