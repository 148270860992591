import React from 'react'
import CustomCard from '../components/Card'

const NotFound = () => {
  return (
    <CustomCard title="404 - Page introuvable">
      <p>Bonjour Voyageur. Il semble que vous soyez perdu.</p>
    </CustomCard>
  )
}

export default NotFound
