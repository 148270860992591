import React, { useEffect, useState } from 'react'
import CustomCard from '../components/Card'
import { useParams, useNavigate, Link } from 'react-router-dom'

const VerifyEmail = () => {
  const { token } = useParams()
  const navigate = useNavigate()

  const [message, setMessage] = useState('')

  useEffect(() => {
    document.title = "Validation de l'email"

    setMessage('Vérification en cours...')

    fetch('http://localhost:8000/verify-email/' + token).then((response) => {
      if (response.ok) {
        setMessage(
          'Votre email a été verifié ! Vous allez être redirigé vers le site dans quelques secondes.'
        )

        setTimeout(() => navigate('/login'), 5000)

        return true
      }

      setMessage(
        'Impossible de vérifier votre adresse email, veuillez réessayer.'
      )
      return false
    })
  }, [token, navigate])

  return (
    <>
      <CustomCard className="w-50 mx-auto mt-5">{message}</CustomCard>
      <div className="text-center p-4">
        <Link to="/login">Connexion</Link>
      </div>
    </>
  )
}

export default VerifyEmail
