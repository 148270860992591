import React, { useContext } from 'react'
import { getRequest } from '../../utils/requestUtils'
import { Link, useNavigate } from 'react-router-dom'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import * as Icon from 'react-bootstrap-icons'
import { Avatar } from 'primereact/avatar'
import { useUser } from '../../hooks/useUser'

function Header() {
  const { user, setUser } = useUser()
  const navigate = useNavigate()

  const handleLogout = () => {
    localStorage.removeItem('user')
    setUser(false)

    getRequest('/logout').then(() => {
      localStorage.removeItem('token')

      navigate('/login')
    })
  }

  return (
    <header className="p-4 bg-white rounded-3 shadow-sm d-flex justify-content-between align-items-center">
      <Link to="/" className="btn btn-light">
        <img
          src="/images/logo-raccoonts_horizontal-496x100.png"
          alt="Raccoonts"
          height="35"
          title="Raccoonts"
        />
      </Link>
      <nav className="d-flex align-items-center">
        <Link to="/profile">
          <Avatar label="P" shape="circle" className="me-1" />
          {user.firstname}
        </Link>
        <OverlayTrigger
          overlay={<Tooltip>Déconnexion</Tooltip>}
          placement="bottom"
        >
          <button
            className="btn btn-link d-flex align-items-center"
            onClick={handleLogout}
          >
            <Icon.Power />
          </button>
        </OverlayTrigger>
      </nav>
    </header>
  )
}

export default Header
