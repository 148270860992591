import axios from 'axios'
import { toast } from 'react-toastify'

export const domain = process.env.REACT_APP_BACKEND_URL

export const getToken = () => {
  if (localStorage.getItem('token')) {
    return { Authorization: 'Bearer ' + localStorage.getItem('token') }
  }

  return {}
}

export const getHeaders = () => {
  return {
    headers: {
      'Content-Type': 'application/json',
      ...getToken(),
    },
  }
}

export const getRequest = (route) => {
  return baseRequest(axios.get(domain + route, getHeaders()))
}

export const postRequest = async (route, data) => {
  return baseRequest(axios.post(domain + route, data, getHeaders()))
}

export const putRequest = async (route, data) => {
  return baseRequest(axios.put(domain + route, data, getHeaders()))
}

export const deleteRequest = (route) => {
  return baseRequest(axios.delete(domain + route, getHeaders()))
}

export const uploadFile = (route, data) => {
  return axios.post(domain + route, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
      ...getToken(),
    },
  })
}

export const baseRequest = async (response) => {
  try {
    return await response
  } catch (error) {
    handleError(error)
  }
}

const handleError = (error) => {
  toast.error('Erreur : ' + error)

  if (axios.isAxiosError(error) && error.response.status === 401) {
    localStorage.removeItem('token')
    localStorage.removeItem('user')

    window.location.reload(true)
  }

  if (axios.isAxiosError(error) && error.response.status === 404) {
    window.location.href = '/404'
  }
}
