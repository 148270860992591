import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import CustomCard from '../../components/Card'
import { deleteRequest, getRequest } from '../../utils/requestUtils'
import moment from 'moment'
import ActionButtonGroup from '../../components/ActionButtonGroup'

const CustomerTable = () => {
  const [customers, setCustomers] = useState([])

  useEffect(() => {
    getRequest('/customers').then((response) => {
      setCustomers(response.data)
    })
  }, [])

  const onDelete = (id) => {
    deleteRequest('/customer/' + id).then(() => {})
  }

  return (
    <CustomCard title="Mes clients">
      <Link to="/customers/new" className="btn btn-primary">
        Ajouter un client
      </Link>
      <table className="table table-striped my-4">
        <thead>
          <tr>
            <th>#</th>
            <th>Nom</th>
            <th>Crée le</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {customers &&
            customers.map((customer) => (
              <tr key={customer.id}>
                <td>{customer.id}</td>
                <td>{customer.name}</td>
                <td>{moment(customer.created_at).format('DD/MM/YYYY')}</td>
                <td>
                  <ActionButtonGroup
                    id={customer.id}
                    type="customers"
                    onDelete={() => onDelete(customer.id)}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </CustomCard>
  )
}

export default CustomerTable
