import React, { useEffect, useRef, useState } from 'react'
import { Col, Form, Row, Table } from 'react-bootstrap'
import InvoiceLineForm from './InvoiceLineForm'
import FormInput from './FormInput'
import { getRequest } from '../../utils/requestUtils'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { SplitButton } from 'primereact/splitbutton'
import { Checkbox } from 'primereact/checkbox'
import lodash from 'lodash'

const InvoiceForm = ({
  invoice,
  setInvoice,
  handleSubmit,
  isButtonLoading,
}) => {
  const [companies, setCompanies] = useState([])
  const [customers, setCustomers] = useState([])
  const [products, setProducts] = useState([])
  const subtotal = useRef(0)

  useEffect(() => {
    getRequest('/companies').then((response) => {
      setCompanies(response.data)
    })

    getRequest('/customers').then((response) => {
      setCustomers(response.data)
    })

    getRequest('/products/suggestions').then((response) => {
      setProducts(response.data)
    })
  }, [])

  if (invoice.products) {
    invoice.total_excl_tax = invoice.products.reduce(
      (previousValue, product) => {
        if (product.type === 1) {
          return lodash
            .round(
              Number(previousValue) +
                Number(product.price) * Number(product.quantity),
              2
            )
            .toFixed(2)
        }

        return lodash.round(previousValue, 2)
      },
      0
    )

    invoice.total_vat = invoice.products.reduce((previousValue, product) => {
      if (product.type === 1) {
        return lodash
          .round(
            Number(previousValue) +
              (Number(product.price_incl_tax) -
                Number(product.price) * Number(product.quantity)),
            2
          )
          .toFixed(2)
      }

      return previousValue
    }, 0)

    invoice.total_incl_tax = invoice.products.reduce(
      (previousValue, product) => {
        if (product.type === 1) {
          return (
            Number(previousValue) +
            Number(product.price_incl_tax) -
            Number(invoice.discount)
          )
        }

        return previousValue
      },
      0
    )
  }

  const handleAddLine = (type = 1) => {
    const newProduct = {
      text: '',
      quantity: 0,
      price: 0,
      tax: 0,
      price_incl_tax: 0,
      type,
    }

    setInvoice({
      ...invoice,
      ...{ products: [...invoice.products, newProduct] },
    })
  }

  const handleChange = (e, name) => {
    setInvoice({ ...invoice, ...{ [name]: e.target.value } })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col>
          <h3>Société</h3>
          <Dropdown
            options={companies}
            optionLabel="name"
            value={companies.find(
              (company) => company.id === invoice.company_id
            )}
            onChange={(e) =>
              setInvoice({
                ...invoice,
                ...{ company_id: e.target.value.id },
              })
            }
            placeholder="Votre société"
            className="w-100"
            filter
          />
          {invoice.id && (
            <div className="flex mt-3">
              <Checkbox
                inputId="update-company"
                checked={invoice.update_company}
                onChange={() =>
                  setInvoice({
                    ...invoice,
                    update_company: !invoice.update_company,
                  })
                }
              />
              <label htmlFor="update-company" className="ms-2" role="button">
                Forcer la mise à jour de la société dans la facture
              </label>
            </div>
          )}
        </Col>
        <Col>
          <h3>Client</h3>
          <Dropdown
            options={customers}
            optionLabel="name"
            value={customers.find(
              (customer) => customer.id === invoice.customer_id
            )}
            onChange={(e) =>
              setInvoice({
                ...invoice,
                ...{ customer_id: e.target.value.id },
              })
            }
            placeholder="Votre société"
            className="w-100"
            filter
          />
          {invoice.id && (
            <div className="flex mt-3">
              <Checkbox
                inputId="update-customer"
                checked={invoice.update_customer}
                onChange={() =>
                  setInvoice({
                    ...invoice,
                    update_customer: !invoice.update_customer,
                  })
                }
              />
              <label htmlFor="update-customer" className="ms-2" role="button">
                Forcer la mise à jour des informations client dans la facture
              </label>
            </div>
          )}
        </Col>
      </Row>
      <Row className="mt-3">
        <h3>Produits</h3>
        <Col>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th></th>
                <th>Description</th>
                <th>Prix HT</th>
                <th>Quantité</th>
                <th>Taxe</th>
                <th>Total TTC</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {invoice.products &&
                invoice.products.map((product, index) => {
                  if (product.type === 1) {
                    subtotal.current += Number(product.price_incl_tax)
                  }

                  if (product.type === 3) {
                    product.price_incl_tax = Number(subtotal.current)
                    subtotal.current = 0
                  }

                  return (
                    <InvoiceLineForm
                      key={index}
                      id={index}
                      product={product}
                      invoice={invoice}
                      setInvoice={setInvoice}
                      products={products}
                      subtotal={subtotal}
                    />
                  )
                })}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <div className="card flex justify-content-center">
            <SplitButton
              label="Ajouter un produit"
              icon="pi pi-plus"
              onClick={() => handleAddLine()}
              model={[
                {
                  label: 'Ajouter un titre',
                  command: () => handleAddLine(2),
                },
                {
                  label: 'Ajouter un sous-total',
                  command: () => handleAddLine(3),
                },
              ]}
            />
          </div>
        </Col>
        <Col md={{ offset: 5 }}>
          <Table>
            <tbody>
              <tr>
                <td className="align-middle">Total HT</td>
                <td>
                  <FormInput
                    name="total_excl_tax"
                    value={invoice.total_excl_tax}
                    symbol="&euro;"
                    stateChanger={() => {}}
                  />
                </td>
              </tr>
              <tr>
                <td className="align-middle">Taxes</td>
                <td>
                  <FormInput
                    name="total_vat"
                    value={invoice.total_vat}
                    symbol="&euro;"
                    stateChanger={() => {}}
                  />
                </td>
              </tr>
              <tr>
                <td className="align-middle">Remise</td>
                <td>
                  <FormInput
                    type="number"
                    name="discount"
                    value={invoice.discount}
                    symbol="&euro;"
                    stateChanger={(e) => handleChange(e, 'discount')}
                  />
                </td>
              </tr>
              <tr>
                <th className="align-middle">Total&nbsp;TTC</th>
                <th>
                  <FormInput
                    type="number"
                    name="total_incl_tax"
                    value={invoice.total_incl_tax}
                    symbol="&euro;"
                  />
                </th>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={3}>
          <Form.Control
            value={invoice.text}
            onChange={(e) => handleChange(e, 'text')}
            as="textarea"
            placeholder="Merci pour votre commande."
            style={{ height: '100px' }}
          />
        </Col>
      </Row>
      <Button
        type="submit"
        label="Enregistrer"
        disabled={isButtonLoading}
        loading={isButtonLoading}
      ></Button>
    </Form>
  )
}

export default InvoiceForm
