import React, { useEffect, useState } from 'react'
import CustomCard from '../../components/Card'
import { getRequest, putRequest } from '../../utils/requestUtils'
import InvoiceForm from '../../components/Form/InvoiceForm'
import { useNavigate, useParams } from 'react-router-dom'
import { isValid } from '../../utils/invoiceUtils'
import { Button } from 'primereact/button'
import { Badge } from 'primereact/badge'
import { validate } from '../../api/invoice'
import ButtonInvoiceDownloadPdf from '../../components/Invoice/ButtonInvoiceDownloadPdf'
import useDocumentTitle from '../../hooks/useDocumentTitle'
import { toast } from 'react-toastify'
import ButtonInvoiceSendEmail from '../../components/Invoice/ButtonInvoiceSendEmail'

const UpdateInvoice = ({ type = 1 }) => {
  const { id } = useParams()
  const [invoice, setInvoice] = useState({
    formatted_number: '',
    company_id: 0,
    update_company: true,
    customer_id: 0,
    update_customer: false,
    products: [
      {
        text: '',
        quantity: 0,
        price: 0.0,
        tax: 0,
        price_incl_tax: 0.0,
        type: 1,
      },
    ],
    total_excl_tax: 0,
    discount: 0,
    total_incl_tax: 0,
    total_vat: 0,
    text: '',
    type,
    validatedAt: null,
  })
  const [isButtonLoading, setButtonLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    getRequest('/invoice/' + id).then((response) => {
      setInvoice(response.data)
    })
  }, [id])

  useDocumentTitle(invoice.id ? `Facture #${invoice.id}` : null)

  const handleSubmit = (e) => {
    e.preventDefault()

    setButtonLoading(true)

    if (isValid(invoice)) {
      putRequest('/invoice/' + invoice.id, invoice).then(() => {
        toast.success('Sauvegarde réussie !')
        setButtonLoading(false)
      })
    }
  }

  const handleValidate = async () => {
    const { data } = await validate(invoice)

    setInvoice(data)
  }

  const handleTransform = () => {
    getRequest('/invoice/transform/' + id).then(({ data }) => {
      navigate('/invoices/' + data.id)
      toast.success(`Le devis a été transformé en facture #${data.id}.`)
    })
  }

  const toolBar = () => {
    return (
      <div className="mb-4 d-flex justify-content-between">
        <h2 className="d-flex align-self-stretch">
          {type === 1 ? `Modifier une facture` : `Modifier un devis`}
          {invoice.validatedAt && (
            <Badge
              value={type === 1 ? `Payée` : `Validé`}
              className="mt-2 ms-1"
              severity="success"
            />
          )}
        </h2>

        <div>
          {invoice.type === 2 && invoice.validatedAt ? (
            <Button
              label="Transformer en facture"
              className="me-3"
              color="primary"
              onClick={handleTransform}
            />
          ) : (
            !invoice.validatedAt && (
              <Button
                className="me-3"
                color="success"
                label={
                  invoice.type === 1
                    ? `Marquer comme payée`
                    : `Valider le devis`
                }
                onClick={handleValidate}
              />
            )
          )}
          <ButtonInvoiceDownloadPdf invoice={invoice} />
          <ButtonInvoiceSendEmail invoice={invoice} />
        </div>
      </div>
    )
  }

  return (
    <>
      <CustomCard className="mb-4">
        {toolBar()}
        <InvoiceForm
          invoice={invoice}
          setInvoice={setInvoice}
          handleSubmit={handleSubmit}
          isButtonLoading={isButtonLoading}
        />
      </CustomCard>
    </>
  )
}

export default UpdateInvoice
