import { toast } from 'react-toastify'
import lodash from 'lodash'
export function calculPriceExclTax(product) {
  return Number(product.price) * Number(product.quantity)
}

export function calculTax(product) {
  return Number(product.tax) * Number(product.quantity)
}

export function calculPriceInclTax({ price, tax, quantity }) {
  const result = Number(price) * (1 + Number(tax) / 100) * Number(quantity)

  return lodash.round(result, 2).toFixed(2)
}

export function isValid(invoice) {
  if (!invoice.company_id) {
    toast.error('Veuillez choisir une société.')
    return false
  }

  if (!invoice.customer_id) {
    toast.error('Veuillez choisir un client.')
    return false
  }

  return true
}
