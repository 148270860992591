import { useEffect, useState } from 'react'
import { domain, getToken } from '../utils/requestUtils'

export default function useFetch({ route, headers = {}, options = {} }) {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [errors, setErrors] = useState(null)

  useEffect(() => {
    setLoading(true)
    setErrors(null)
    setData(null)

    fetch(domain + route, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...getToken(),
        ...headers,
      },
      ...options,
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        }

        switch (response.status) {
          case 401:
            localStorage.removeItem('token')
            localStorage.removeItem('user')

            window.location.reload(true)
            break
          case 404:
            window.location.href = '/404'
            break
          default:
        }

        return false
      })
      .then((data) => {
        setData(data)
      })
      .catch((e) => {
        setErrors(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [route])

  return { loading, data, errors }
}
