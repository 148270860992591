import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import CustomCard from '../../components/Card'
import { deleteRequest, getRequest } from '../../utils/requestUtils'
import moment from 'moment'
import ActionButtonGroup from '../../components/ActionButtonGroup'

const CompanyTable = () => {
  const [companies, setCompanies] = useState([])

  useEffect(() => {
    getRequest('/companies').then((response) => {
      setCompanies(response.data)
    })
  }, [])

  const onDelete = (id) => {
    deleteRequest('/company/' + id).then(() => {})
  }

  return (
    <CustomCard title="Mes sociétés">
      <Link to="/companies/new" className="btn btn-primary">
        Ajouter une société
      </Link>
      <table className="table table-striped my-4">
        <thead>
          <tr>
            <th>#</th>
            <th>Nom</th>
            <th>Crée le</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {companies &&
            companies.map((company) => (
              <tr key={company.id}>
                <td>{company.id}</td>
                <td>{company.name}</td>
                <td>{moment(company.created_at).format('DD/MM/YYYY')}</td>
                <td>
                  <ActionButtonGroup
                    id={company.id}
                    type="companies"
                    onDelete={() => onDelete(company.id)}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </CustomCard>
  )
}

export default CompanyTable
