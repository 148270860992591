import React, { useState } from 'react'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Dialog } from 'primereact/dialog'
import { Form } from 'react-bootstrap'
import { InputTextarea } from 'primereact/inputtextarea'
import { postRequest } from '../../utils/requestUtils'

const ButtonInvoiceSendEmail = ({ invoice }) => {
  const [isLoading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)

  const handleClick = () => {
    setVisible(true)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    setLoading(true)

    const formData = new FormData(e.target)

    postRequest(`/invoice/${invoice.id}/send`, formData).finally(() => {
      setLoading(false)
    })
  }

  return (
    <>
      <Button
        label="Envoyer par email"
        onClick={handleClick}
        loading={isLoading}
      />
      <Dialog
        visible={visible}
        header="Envoyer par email"
        onHide={() => setVisible(false)}
      >
        <Form onSubmit={handleSubmit} className="flex flex-column gap-3">
          <div className="flex flex-column gap-2">
            <label htmlFor="recipient">Destinataire</label>
            <InputText
              type="text"
              name="recipient"
              placeholder="exemple@exemple.com"
              id="recipient"
            />
          </div>
          <div className="flex flex-column gap-2">
            <label htmlFor="content">Contenu de l'email</label>
            <InputTextarea name="content" id="content" />
          </div>
          <Button
            type="submit"
            label="Envoyer"
            className="mt-3"
            icon="pi pi-send"
          />
        </Form>
      </Dialog>
    </>
  )
}

export default ButtonInvoiceSendEmail
