import React, { useState } from 'react'
import CustomCard from '../../components/Card'
import FormInput from '../../components/Form/FormInput'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { putRequest } from '../../utils/requestUtils'
import { useUser } from '../../hooks/useUser'

function UserProfile() {
  const { user, setUser } = useUser()

  const [newUser, setNewUser] = useState(user)

  const handleChange = (e, key) => {
    setNewUser({ ...newUser, [key]: e.target.value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const formData = JSON.stringify(Object.fromEntries(new FormData(e.target)))

    const updateRequest = await putRequest('/user', formData)

    alert('Votre profil a été modifié.')
  }

  return (
    <CustomCard>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md="6">
            <h2>Informations</h2>
            <Row>
              <Col md="6">
                <FormInput
                  label="Prénom"
                  name="firstname"
                  value={newUser.firstname}
                  stateChanger={(e) => handleChange(e, 'firstname')}
                />
              </Col>
              <Col md="6">
                <FormInput
                  label="Nom"
                  name="lastname"
                  value={newUser.lastname}
                  stateChanger={(e) => handleChange(e, 'lastname')}
                />
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormInput
                  label="Adresse email"
                  name="email"
                  value={newUser.email}
                  stateChanger={(e) => handleChange(e, 'email')}
                />
              </Col>
              <Col md="6">
                <FormInput label="Numéro de téléphone" name="phone" />
              </Col>
            </Row>

            <hr />
            <h2>Sécurité</h2>
            <Row>
              <Col md="6">
                <FormInput label="Ancien mot de passe" name="old_password" />
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormInput label="Nouveau mot de passe" name="new_password" />
              </Col>
              <Col md="6">
                <FormInput
                  label="Confirmez le nouveau mot de passe"
                  name="confirm_new_password"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Button type="submit">Enregistrer</Button>
      </Form>
    </CustomCard>
  )
}

export default UserProfile
