import { useEffect } from 'react'

export default function useDocumentTitle(title) {
  useEffect(() => {
    document.title = 'Raccoonts'

    if (title !== null && title !== undefined && title !== '') {
      document.title = `${title} | Raccoonts`
    }
  }, [title])
}
