import axios from 'axios'
import { domain, getToken } from '../../utils/requestUtils'
import React, { useState } from 'react'
import { Button } from 'primereact/button'

const ButtonInvoiceDownloadPdf = ({ invoice }) => {
  const [isLoading, setLoading] = useState(false)

  const downloadPdf = async () => {
    setLoading(true)

    axios
      .get(`${domain}/invoice/${invoice.id}/pdf`, {
        headers: {
          ...getToken(),
        },
        responseType: 'blob',
      })
      .then((response) => {
        const blob = new File(
          [response.data],
          `${invoice.formatted_number}.pdf`,
          {
            type: 'application/pdf',
          }
        )
        const downloadUrl = URL.createObjectURL(blob)

        window.open(downloadUrl, '_blank')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Button
      label="Télécharger le PDF"
      onClick={downloadPdf}
      loading={isLoading}
      className="me-3"
    />
  )
}

export default ButtonInvoiceDownloadPdf
