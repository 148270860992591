import React from 'react'
import { Form } from 'react-bootstrap'
import { InputText } from 'primereact/inputtext'

const FormInput = ({
  label,
  type,
  name,
  value,
  defaultValue,
  placeholder,
  text,
  symbol,
  stateChanger,
  minLength,
  required,
  readOnly,
  disabled,
}) => {
  return (
    <Form.Group className={label && 'mb-3'}>
      {label && (
        <Form.Label>
          {label}
          {required && <small className="ms-1 text-muted">(obligatoire)</small>}
        </Form.Label>
      )}
      <div className="p-inputgroup flex-1">
        <InputText
          type={type}
          name={name}
          value={value}
          defaultValue={defaultValue}
          placeholder={placeholder}
          onChange={stateChanger}
          minLength={minLength}
          required={required}
          readOnly={readOnly}
          disabled={disabled}
        />
        {symbol && <span className="p-inputgroup-addon">{symbol}</span>}
      </div>
      {text && <Form.Text className="text-muted">{text}</Form.Text>}
    </Form.Group>
  )
}

export default FormInput
